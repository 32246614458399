<template>
  <div>
    <!-- 手续登记 -->
    <div class="content1">
      <div class="all">
        <div class="SearchBar">
          <!--搜索框-->
          <el-form
            ref="search"
            :inline="true"
            :model="search"
            size="small"
            label-width="80px"
          >
            <el-form-item label="工程编号/名称" label-width="115px">
              <el-input
                v-model="search.numberOrName"
                placeholder="请输入"
                class="SearchInput"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item  label="登记时间">
              <el-date-picker
                v-model="searchDatatime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
<el-form-item  label="" label-width="30px">
  <div>
            <el-button
              type="primary"
              size="small"
              @click="getList(1)"
              >查询</el-button
            >
            <el-button
              type="primary"
              plain
              size="small"
              @click="reset"
              >重置</el-button
            >
            </div>
          </el-form-item>
          </el-form>
        </div>
        <div class="table">
          <div class="flex-con-end">
        
              <el-button type="primary" @click="showadd" size="small" plain
                >登记</el-button
              >
          </div>
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 15px 0"
            border
            class="table-height-in"
            :stripe="true"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="projectNumber"
              label="工程编号"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="projectType"
              label="工程类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="explain"
              label="手续说明"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="realname"
              label="登记人"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column
            >>
            <el-table-column
              prop="createTime"
              label="登记时间"
              align="center"
              show-overflow-tooltip
            ></el-table-column>

            <el-table-column
              prop="operation"
              label="操作"
              align="left"
              width="200px"
            >
              <template slot-scope="{ row }">
                <el-button type="primary" size="small" @click="desc(row)"
                  >查看</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click="del(row)"
                  v-if="coder == 'admin'"
                  >删除</el-button
                >
              </template>
              <!--  -->
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="search.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="search.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 登记 -->
    <el-dialog
      title="登记"
      :visible.sync="dialogVisible"
      width="30%"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-form
        ref="addform"
        :model="form"
        label-width="130px"
        :rules="rule"
        size="small"
      >
        <div>
          <el-form-item label="工程名称" prop="workId">
            <el-select
              v-model="form.workId"
              placeholder="请选择"
              filterable
              :style="breadth"
              value-key="id"
              :disabled="disabled"
              style="width: 173px"
            >
              <el-option
                v-for="item in teamList"
                :key="item.id"
                :label="item.projectNumber + ' ' + item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手续附件类型" prop="attachmentType">
            <el-radio-group v-model="form.attachmentType" :disabled="disabled">
              <el-radio label="1">图片</el-radio>
              <el-radio label="2">pdf</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="合同附件"
            prop="attachmentPdf"
            v-if="form.attachmentType == '2'"
          >
            <el-upload
              :disabled="disabled"
              ref="upload"
              action="#"
              v-model="form.attachmentPdf"
              :auto-upload="true"
              :http-request="repairBeforUploadPDFFun"
              list-type="fileList"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :file-list="FilefileList"
              :before-upload="beforUploadImg"
              accept=".pdf"
              :limit="1"
              :on-exceed="msgLimit"
            >
              <el-button size="small" type="text">上传PDF附件</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item
            label="工程手续"
            prop="attachmentImg"
            v-if="form.attachmentType == '1'"
          >
            <el-upload
              :disabled="disabled"
              v-model="form.attachmentImg"
              ref="upload"
              action="#"
              :auto-upload="true"
              :http-request="repairBeforUploadIMGFun"
              list-type="picture-card"
              :on-preview="handlePictureCardPreviewIMG"
              :on-remove="handleRemoveImg"
              :before-upload="beforUploadImg"
              :on-change="beforHFhandleChangeImg"
              :file-list="repairBeforFilefileList"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
            >
              <div style="height: 20px">
                <img src="../../assets/image/upload.png" />
              </div>
              <div>
                <span>上传图片</span>
              </div>
              <!-- <i class="el-icon-plus"></i> -->
            </el-upload>
            <el-dialog :visible.sync="dialogVisibleIMG" :modal="false">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>
          <el-form-item label="手续说明" prop="applyId">
            <el-input
              :disabled="disabled"
              v-model="form.explain"
              placeholder="请输入"
              :style="breadth"
              class="SearchInput"
              type="textarea"
              maxlength="100"
              show-word-limit
              clearable
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-right: 10px" @click="clone">取消</el-button>
        <el-button type="primary" v-if="disabled" @click="eadit"
          >编 辑</el-button
        >
        <el-button
          type="primary"
          v-if="updatetes && !disabled"
          @click="saveupdate"
          >确 定</el-button
        >
        <el-button
          type="primary"
          v-if="!updatetes && !disabled"
          @click="saveadd"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { uploadImg1,uploadImg } from "../../RequestPort/apply/apply.js";
import getNowFormatDate from "./../../common/js/nowDate.js";
import {
  list,
  show,
  save,
  update,
  deleteList,
  projectStatus,
  getProjectList,
} from "@/RequestPort/earlier/index.js";
export default {
  name: "conntract",
  mixins: [],
  data() {
    return {
      name: "",
     // disabled: false,
      updatetes: false,
      search: { current: 1, size: 100 }, //搜索
      // 时间组
      searchDatatime: [],
      total: 10,
      tableData: [], //列表对象
      teamList: [],
      // 只要没有进去验收中，可以调整！同时，进入验收中，只要没有相关的验收业务数据产生，可以删除；同时可增加新的验收
      disabled: false,
      dialogVisible: false,
      dialogVisibleIMG: false,
      dialogImageUrl: "",
      // 新增编辑输入框宽度
      breadth: "width:300px",
      // 表单提交
      form: { resource: 1, attachment: [], attachmentImg: [] ,attachmentType:'1'},
      dataObj: {
        attachment: [],
      },
      rule: {
        workId: {
          required: true,
          message: "请选择工程名称",
          trigger: "change",
        },
        attachmentType: {
          required: true,
          message: "请选择手续附件类型",
          trigger: "change",
        },
        attachmentImg: {
          required: true,
          message: "请上传工程手续",
          trigger: "blur",
        },
        attachmentPdf: {
          required: true,
          message: "请上传附件合同",
          trigger: "change",
        },
      },
      FileFormData: new FormData(),
      repairBeforFileFormData: new FormData(),
      applyflag: "",
      // 验收范围（确认弹窗）
      activeName: "接口返回数据",
      FilefileList: [],
      repairBeforFilefileList: [],
      coder: "",
    };
  },
  mounted() {
    this.coder = localStorage.getItem("roleCodes");
    // 工程状态
    this.getprojectStatus();
    // 登记列表
    this.getList();
  },
  methods: {
    /**
     * 工程状态
     */
    getprojectStatus() {
      // projectStatus().then(res => {
      //     console.log(res)
      // })
      getProjectList().then((res) => {
        this.teamList = res.data;
      });
    },
    /**
     * 编辑按钮
     */
    eadit() {
      this.disabled = false;
      this.updatetes = true;
    },
    /**
     * 查询列表
     */
    getList(current) {
      if (this.searchDatatime) {
        this.search.startTime = this.searchDatatime[0];
        this.search.endTime = this.searchDatatime[1];
      }
      if (current) {
        this.search.current = 1;
      }
      //查列表
      list(this.search).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    /**
     * 清空
     */
    clone() {
      this.dialogVisible = false;
      this.repairBeforFilefileList = [];
      this.FilefileList = [];
    },
    /**
     * 上传pdf
     */
    repairBeforUploadPDFFun(e) {
      this.FileFormData.append("file", e.file);
      let workName = this.teamList.find(el => el.id == this.form.workId).name
      this.repairBeforFileFormData.set(
        "text",
        '工程名称：'+
        workName +
          "##用户名：" +
          localStorage.getItem("userName") +
          "##当前时间：" +
          getNowFormatDate()
      );
      this.form.attachmentImg = []
      uploadImg(this.FileFormData).then((res) => {
        if (res.code == 200) {
          this.form.attachmentPdf = res.data.url;
          console.log(this.form);
        }
        this.FileFormData.delete("file");
      });
    },
    // 上传图片
    repairBeforUploadIMGFun(e) {
      this.repairBeforFileFormData.set("file", e.file);
     let workName = this.teamList.find(el => el.id == this.form.workId).name
      this.repairBeforFileFormData.set(
        "text",
        '工程名称：'+
        workName +
          "##用户名：" +
          localStorage.getItem("userName") +
          "##当前时间：" +
          getNowFormatDate()
      );
      this.form.attachmentPdf = ''
      if(!this.form.attachmentImg) this.form.attachmentImg = []
      uploadImg1(this.repairBeforFileFormData).then((res) => {
        // this.form.attachmentImg.push(res.data.url);
        res.data.uid = e.file.uid
        console.log(res.data)
        this.form.attachmentImg.push(res.data);
        this.repairBeforFileFormData.delete("file");
      });
    },
    handlePictureCardPreviewIMG(file) {
      // this.$refs.ptfshow.previewPDF(file.url);
      this.dialogImageUrl = file.url;
      this.dialogVisibleIMG = true;
    },
    handlePictureCardPreview(file) {
      this.$refs.ptfshow.previewPDF(file.url);
    },
    /**
     * 移除文件
     */
    handleRemove(file) {
      this.FilefileList = [];
      this.form.pcBackupsUrl = "";
    },
    /**
     * 删除图片的时候
     */
    handleRemoveImg(file){
      console.log(this.form.attachmentImg)
      this.form.attachmentImg.forEach((el,index) => {
        if(el.uid == file.uid) {
          this.form.attachmentImg.splice(index,1)
        } 
        if((el == file.url) && !this.disabled) {
          this.form.attachmentImg.splice(index,1)
        }
      })
    },
    // 上传文件
    beforUploadImg(file) {
      this.form.name = file.name;
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["pdf"];
      if (whiteList.indexOf(fileSuffix) === -1 && fileSuffix == "pdf") {
        this.$message.error("上传文件只能是pdf");
        return false;
      }
      const size = file.size / 1024 / 1024;
      if (size > 100) {
        this.$message.error("大小必须小于100M");
        return false;
      }
    },
    beforHFhandleChangeImg(file, fileList) {
      this.FilefileList = fileList;
    },
    msgLimit(file, fileList) {
      this.$message.error("只能上传一个pdf");
    },
    //重置查询条件
    reset() {
      (this.search = {
        current: 1,
        size: 100,
        dateType: "1",
      }),
        (this.searchDatatime = []);
      this.getList(1);
    },

    handleSizeChange(val) {
      this.search.size = val;
      this.getList(1);
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.getList();
    },
    /**
     * 删除
     */
    del(row) {
      this.$confirm(`确认删除工程【${row.projectNumber}】手续文件`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        let params = {
          id: row.id,
        };
        deleteList(params).then((res) => {
          this.$message.success("删除成功");
          this.getList(1);
        });
      });
    },
    /**
     * 新增弹窗
     */
    showadd() {
      this.updatetes = false;
      this.dialogVisible = true;
      this.disabled = false
      this.form = {
        resource: 1,
        attachmentType: '1',
        workId: " ",
        attachmentImg: [],
      };
    },
    close() {
      this.dialogVisible = false;
      this.repairBeforFilefileList = [];
      this.FilefileList = [];
    },
    /**
     * 新增工程
     */
    saveadd() {
      if (this.form.attachmentPdf) {
        this.form.attachment = this.form.attachmentPdf;
      }
      if (this.form.attachmentImg.length > 0) {
        let imgList = []
        this.form.attachmentImg.forEach(el => {
          imgList.push(el.url)
        })
        this.form.attachment = imgList.join(",");
      }
      this.$refs["addform"].validate((valid) => {
        if (valid) {
          save(this.form).then((res) => {
            this.$message.success(res.data);
            this.getList(1);
            this.form = {
              attachmentImg: [],
            };
            this.dialogVisible = false;
          });
        }
      });
    },
    /**
     * 编辑
     */
    saveupdate() {
      if (this.form.attachmentPdf) {
        this.form.attachment = this.form.attachmentPdf;
      }
      if (this.form.attachmentImg.length > 0) {
        let imgList = []
        console.log(this.form.attachmentImg)
        // return
        this.form.attachmentImg.forEach(el => {
          if(el.url){
          imgList.push(el.url)
          } else {
            imgList.push(el)
          }
        })
        this.form.attachment = imgList.join(",");
        // this.form.attachment = this.form.attachmentImg.join(",");
      }
      this.$refs["addform"].validate((valid) => {
        if (valid) {
          update(this.form).then((res) => {
            this.$message.success(res.data);
            this.getList(1);
            this.form = {
              attachmentImg: [],
            };
            this.dialogVisible = false;
          });
        }
      });
    },
    /**
     * 查看
     */
    desc(row) {
      this.dialogVisible = true;
      this.disabled = true;
      this.repairBeforFilefileList = [];
      this.FilefileList = [];
      show({ id: row.id }).then((res) => {
        if (res.code == 200) {
          this.form = res.data.formalities;
          if (this.form.attachmentType == '1') {
            this.form.attachmentImg = this.form.attachment.split(",");
            this.form.attachment.split(",").forEach((el) => {
              this.repairBeforFilefileList.push({ url: el });
            });
          }
          if (this.form.attachmentType == '2') {
            this.form.attachmentPdf = this.form.attachment;
            this.FilefileList.push({
              url: this.form.attachment,
              name: res.data.formalities.name,
            });
          }
        }
        this.$refs['addform'].resetFields() //resetFields
      });
    },
  },
};
</script>

<style  lang="less" scoped>
::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.searchType {
  width: 210px;
  box-shadow: 1px 1px 5px #888888;
  position: absolute;
  z-index: 100;
  background-color: #fff;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchInput {
  width: 100%;
}
.SearchOptions {
  width: 100%;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  height: auto;
}

.all {
  background-color: #f3f4f8;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 5px;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList {
  display: block;
  margin: 10px 0px;
}
.particulars {
  display: flex;
  flex-direction: row;

  height: 40px;
  position: relative;
  .img {
    border: 1px solid #3f74d5;
    height: 20px;
    margin-right: 2px;
  }
  .state {
    width: 60px;
    height: 30px;
    position: absolute;
    top: -10px;
    left: 80px;
    color: #f18d1b;
    line-height: 30px;
    text-align: center;
    border: 1px solid #f18d1b;
  }
}
</style>
